


















import { Component, Vue } from 'vue-property-decorator'
import StudentSidebar from '@/components/organisms/v3/StudentSidebar.vue'
import StudentHistoryHeader from '@/components/organisms/v3/StudentHistoryHeader.vue'

@Component({
  components: {
    StudentSidebar,
    StudentHistoryHeader,
  },
})
export default class StudentTestViwer extends Vue {
  private async loginUser() {
    await Vue.prototype.$http.httpWithToken.get('/v3/users/loginUser').then((res: any) => {
      const isOnAir = res.data.isStudentOfOnairAcademy
      if (isOnAir) {
        return
      }
      if (!res.data.showCalendarAndGradeBook) {
        this.$router.push({ path: '/student/v3/dashboard' })
      }
    })
  }
  private async mounted() {
    await this.loginUser()
  }
  private studentTestViwerUrl =
    process.env.VUE_APP_MANAMIRU_URL +
    '/grade-viewer?' +
    'branchId=' +
    Vue.prototype.$cookies.get('dataGdls').branchId +
    '&&access-token=' +
    Vue.prototype.$cookies.get('authGdls').accessToken +
    '&&client=' +
    Vue.prototype.$cookies.get('authGdls').client +
    '&&uid=' +
    Vue.prototype.$cookies.get('authGdls').uid
}
